import React from "react";
import './App.css';
import { useAuthenticator,View } from '@aws-amplify/ui-react';
import { NavBar } from './ui-components';
import {Helmet} from "react-helmet";
import logo from "./assets/logo.svg";

const seo_description = "ezList is a powerful platform that lets you create, share, and discover all types of lists. With ezList, you can collaborate with others on your lists, vote on items to prioritize them, and monetize your content through ads or affiliate marketing. Our AI-powered recommendation engine helps you discover new content, while social media integration lets you easily share your lists with a wider audience. With our mobile app, you can stay connected and manage your lists on the go. Plus, with detailed analytics, you can track your performance and optimize your content to maximize engagement. Start exploring ezList today and unleash your creativity!";

function App() {
  return (
    <View className="App">
      
      <Helmet>
        {/* React Helmet is a library that helps in dealing with search engines and 
            social media crawlers by adding meta tags to pages/components on React 
            so the website gives more valuable information to the crawlers. 
            https://blog.logrocket.com/adding-dynamic-meta-tags-react-app-without-ssr/
        */}
        <title>ezList</title>‍
        <meta name="description" content={seo_description} />
        <meta name="twitter:card" content={logo} />        
        <meta name="twitter:site" content="@ezList" />        
        <meta name="twitter:creator" content="@ezList" />        
        <meta name="twitter:title" content="ezList" />        
        <meta name="twitter:description" content={seo_description} />        
        <meta name="twitter:image" content={logo} />        
        <meta property="og:title" content="ezList" />        
        <meta property="og:description" content={seo_description} />        
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="http://ezlist.co/" />
        <meta property="og:site_name" content="ezList" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      </Helmet>
      
      <NavBar/>

    </View>
  );
}

export default App;
